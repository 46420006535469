import React, { Component } from 'react';
// import { Col, Row, Button } from 'react-bootstrap';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
import RouterConstant from '../../constants/RouterConstant';
import SpinnerLoader from '../Loader/Loader';
// import { Header } from '../';
// import { withAuth } from '@okta/okta-react';
import RoleConstant from '../../constants/RoleConstant';
import PropTypes from 'prop-types';

class SSOCallback extends Component {
  constructor(props) {
    super(props);
    this.performAuthReset = this.performAuthReset.bind(this);
    this.getSiteInfo = this.getSiteInfo.bind(this);
  }

  componentDidMount() {
    this.performAuthReset();
  }

  performAuthReset() {

    let self = this;
    let queryString = window.location.hash;
    queryString = queryString.split("?").pop();

    // console.log("queryString : " + JSON.stringify(queryString) );

    const params = new URLSearchParams(queryString);
    let authId = params.get("id");

    const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
    // this.props.auth.getAccessToken()
    //   .then(function (token) {
    const token = idToken.accessToken.accessToken;
    const authorization = {
      Authorization: 'Bearer ' + token
    };

    // console.log("data : " + JSON.stringify(data) );

    utils.makeRequest(this, EndpointConstant.USER_AUTH, { id: authId }, function (result) {
      if (result.hasOwnProperty("userId") && result.hasOwnProperty("sessionToken") && result.hasOwnProperty("role")) {
        utils.setSessionItem("userId", result.userId);
        utils.setSessionItem("sessionToken", result.sessionToken);
        let current = new Date().getTime();
        current = Math.round((current + 1 * 60 * 60 * 1000)/1000);
        utils.setSessionItem("sessionValidity", current);
        utils.setSessionItem("role", result.role);
        if (result.hasOwnProperty("firstName")) {
          utils.setSessionItem("firstName", result.firstName);
        }
        if (result.hasOwnProperty("lastName")) {
          utils.setSessionItem("lastName", result.lastName);
        }
        self.getSiteInfo(null);
      }
      else if (result.hasOwnProperty("error")) {
        utils.gotoPage(RouterConstant.REDIRECTED_UNAPPROVED_USER);
      }
      else if (result.hasOwnProperty("register")) {
        self.getSiteInfo("register");
      }
      else {
        utils.gotoPage(RouterConstant.PAGE_NOT_FOUND);
      }
    }, authorization);
    // });
  }

  getSiteInfo(redirect) {
    const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
    // console.log("ssocallback accesstoken",idToken);
    let token = null;
    if(idToken != null){
      token = idToken.accessToken.accessToken
    }
    utils.makeRequest(this, EndpointConstant.GET_SITE_INFO, {token:token}, function (result) {

      //console.log("result : " + JSON.stringify(result));

      utils.setSessionItem("SiteInfo", JSON.stringify(result));
      if (redirect === 'register') {
        utils.gotoPage(RouterConstant.REGISTRATION_LINK);
      }
      else if (utils.getSessionItem("role") === RoleConstant.Reviewer) {
        utils.gotoPage(RouterConstant.GIMS_REPORTS_LINK);
      }
      else if (utils.getSessionItem("role") === RoleConstant.DataScientist) {
        utils.gotoPage(RouterConstant.DATA_MODEL_REPORTS_LINK);
      }
      else if(utils.getSessionItem('currentUrl')){
        utils.gotoPage(utils.getSessionItem('currentUrl'));
      }
      else {
        utils.gotoPage(RouterConstant.HOME_LINK);
      }

    });
  }

  render() {
    return (
      <div>
        <SpinnerLoader />
      </div>
    );
  }
}
SSOCallback.propTypes = {
  auth: PropTypes.object,
}
export default SSOCallback;


